import React, { Fragment, useState } from 'react';
import { Dialog } from '@material-ui/core';
import VideoRecorder from 'react-video-recorder';
import styles from './VideoRecorderComponent.module.scss';
import Button from '../../Buttons/Button';
import { IoCloseCircleOutline } from 'react-icons/all';
import { $t } from '../../../translations';

const VideoRecorderComponent = ({ open, onRecordingComplete, closePlayer }) => {
  const [height, setHeight] = useState(300);

  const DisconnectedView = () => {
    return <div className={styles.viewContainer} />;
  };

  const LoadingView = () => {
    return <div className={styles.viewContainer} />;
  };

  const renderContent = ({
    isCameraOn,
    isConnecting,
    onTurnOnCamera,
    isRecording,
    onStartRecording,
    onStopRecording,
  }) => {
    const onTurnCameraHandler = () => {
      onTurnOnCamera();
      setHeight(640);
    };
    const closePlayerHandler = () => {
      closePlayer();
      setHeight(300);
    };

    const onStopRecordingHandler = () => {
      onStopRecording();
      setHeight(300);
    };

    const getContent = () => {
      if (!isCameraOn && !isConnecting) {
        return (
          <Fragment>
            <button onClick={closePlayerHandler} className={styles.closeButton}>
              <IoCloseCircleOutline size={24} color={'#d8d8d8'} />
            </button>
            <h2 className={styles.title}>
              {$t('create_event_page.video.connect.title')}
            </h2>
            <h4 className={styles.subtitle}>
              {$t('create_event_page.video.connect.subtitle_1')}
            </h4>
            <h4 className={styles.bottomSubtitle}>
              {$t('create_event_page.video.connect.subtitle_2')}
            </h4>
            <Button
              title={$t('create_event_page.video.connect.button')}
              onClick={onTurnCameraHandler}
            />
          </Fragment>
        );
      }

      if (isConnecting && !isCameraOn) {
        return (
          <p className={styles.title}>
            {$t('create_event_page.video.connecting.title')}
          </p>
        );
      }

      if (isCameraOn && !isRecording) {
        return (
          <div className={styles.cameraViewContainer}>
            <p className={styles.cameraViewTitle}>
              {$t('create_event_page.video.camera.question_part_1')}
              <span className={styles.cameraViewTitleRed}>
                {$t('create_event_page.video.camera.question_part_2')}
              </span>
              {$t('create_event_page.video.camera.question_part_3')}
            </p>
            <button
              onClick={onStartRecording}
              className={styles.cameraViewButton}
            >
              <div className={styles.cameraViewButtonInnerCircle} />
            </button>
            <button onClick={closePlayerHandler} className={styles.closeButton}>
              <IoCloseCircleOutline size={24} color={'#d8d8d8'} />
            </button>
          </div>
        );
      }

      if (isRecording) {
        return (
          <div>
            <div className={styles.cameraViewDot} />
            <button
              onClick={onStopRecordingHandler}
              className={styles.cameraViewButton}
            >
              <div className={styles.cameraViewButtonInnerSquare} />
            </button>
          </div>
        );
      }
    };

    return (
      <div className={styles.renderActionsContainer}>
        <div className={styles.renderActionsInnerWrapper}>{getContent()}</div>
      </div>
    );
  };

  return (
    <Dialog open={open} disableScrollLock={true}>
      <div className={styles.container} style={{ height: height }}>
        <VideoRecorder
          renderDisconnectedView={() => <DisconnectedView />}
          renderLoadingView={() => <LoadingView />}
          renderActions={renderContent}
          onRecordingComplete={onRecordingComplete}
          countdownTime={0}
          constraints={{
            audio: true,
            video: {
              width: { exact: 480, ideal: 480 },
              height: { exact: 640, ideal: 640 },
              aspectRatio: { exact: 0.75, ideal: 0.75 },
              resizeMode: 'crop-and-scale',
            },
          }}
        />
      </div>
    </Dialog>
  );
};

export default VideoRecorderComponent;
