import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_RESPONDENT_EVENT: '/respondent-events/:respondentEventId',
  GET_RESPONDENT_EVENTS: '/respondent-events',
  GET_ACTIVE_RESPONDENT_EVENT: '/respondent-events/single/active',
  CREATE_RESPONDENT_EVENT: '/respondent-events',
  DELETE_RESPONDENT_EVENT: '/respondent-events/:respondentEventId',
  CREATE_RESPONDENT_STEP: '/respondent-steps',
  UPDATE_RESPONDENT_STEP: '/respondent-steps/:respondentStepId',
  SET_RESPONDENT_EVENT_LOWEST: '/respondent-events/lowest',
  SET_RESPONDENT_EVENT_HIGHEST: '/respondent-events/highest',
};

class RespondentEventService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getRespondentEvent = (respondentEventId) => {
    return this.httpService.request({
      url: ROUTES.GET_RESPONDENT_EVENT.replace(
        ':respondentEventId',
        respondentEventId
      ),
      method: HTTP_METHODS.GET,
    });
  };

  getRespondentEvents = () => {
    return this.httpService.request({
      url: ROUTES.GET_RESPONDENT_EVENTS,
      method: HTTP_METHODS.GET,
    });
  };

  getActiveRespondentEvent = () => {
    return this.httpService.request({
      url: ROUTES.GET_ACTIVE_RESPONDENT_EVENT,
      method: HTTP_METHODS.GET,
    });
  };

  createRespondentEvent = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_RESPONDENT_EVENT,
      method: HTTP_METHODS.POST,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    });
  };

  deleteRespondentEvent = (respondentEventId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_RESPONDENT_EVENT.replace(
        ':respondentEventId',
        respondentEventId
      ),
      method: HTTP_METHODS.DELETE,
    });
  };

  createRespondentStep = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_RESPONDENT_STEP,
      method: HTTP_METHODS.POST,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    });
  };

  updateRespondentStep = (data, respondentStepId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_RESPONDENT_STEP.replace(
        ':respondentStepId',
        respondentStepId
      ),
      method: HTTP_METHODS.PUT,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    });
  };

  setRespondentEventsLowest = (data) => {
    return this.httpService.request({
      url: ROUTES.SET_RESPONDENT_EVENT_LOWEST,
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  setRespondentEventsHighest = (data) => {
    return this.httpService.request({
      url: ROUTES.SET_RESPONDENT_EVENT_HIGHEST,
      method: HTTP_METHODS.PUT,
      data,
    });
  };
}

const respondentEventService = new RespondentEventService(httpService);

export default respondentEventService;
