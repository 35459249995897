import React, { useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import styles from './CreateEvent.module.scss';
import { $t } from '../../translations';

const InputComponent = ({ value, onChange, activeStepEvent, activeStep }) => {
  useEffect(() => {
    if (!activeStepEvent?.other_value || activeStepEvent?.other_value === '') {
      onChange('');
    } else {
      onChange(activeStepEvent?.other_value);
    }
  }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputBase
      value={value}
      className={styles.inputWrapper}
      placeholder={$t('create_event_page.events.placeholder')}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default InputComponent;
