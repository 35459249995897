import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  SUBMIT_JOURNEY: '/respondents/submit-journey',
  START_JOURNEY: '/respondents/start',
  GET_JOURNEY_PDF: '/respondents/:respondentId/journey-pdf',
  GET_JOURNEY_EXCEL: '/respondents/:respondentId/journey-excel',
};

class RespondentService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  submitJourney = (data) => {
    return this.httpService.request({
      url: ROUTES.SUBMIT_JOURNEY,
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  startJourney = () => {
    return this.httpService.request({
      url: ROUTES.START_JOURNEY,
      method: HTTP_METHODS.PUT,
    });
  };

  getJourneyPdf = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.GET_JOURNEY_PDF.replace(':respondentId', respondentId),
      method: HTTP_METHODS.GET,
    });
  };

  getJourneyExcel = () => {
    return this.httpService.request({
      url: ROUTES.GET_JOURNEY_EXCEL,
      method: HTTP_METHODS.GET,
    });
  };
}

const respondentService = new RespondentService(httpService);

export default respondentService;
