import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import styles from './AudioRecorderComponent.module.scss';
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder';
import { IoCloseCircleOutline } from 'react-icons/all';
import { secondsToTime } from '../../../helpers/FormatTimeHelper';
import upload from '../../../assets/icons/upload.svg';
import { getPlayerIcon } from '../../../helpers/playerHelper';

const AudioRecorderComponent = ({ open, closePlayer, onRecordingComplete }) => {
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
  } = useAudioRecorder();

  const [discard, setDiscard] = useState(false);

  const stopRecordingHandler = (action) => {
    if (action === 'discard') {
      setDiscard(true);
    }
    closePlayer();
    stopRecording();
  };

  const startRecordingHandler = () => {
    startRecording();
    setDiscard(false);
  };

  const playerControlsHandler = () => {
    switch (status) {
      case 'idle':
        startRecordingHandler();
        break;
      case 'recording':
        pauseRecording();
        break;
      case 'paused':
        resumeRecording();
        break;
      default:
        stopRecording();
        break;
    }
  };

  useEffect(() => {
    if (audioResult) {
      onRecordingComplete({
        audio: audioResult,
        status: status,
        discard: discard,
      });
    }
  }, [audioResult]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      open={open}
      disableScrollLock={true}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <div className={styles.container}>
        <button
          onClick={() => stopRecordingHandler('discard')}
          className={styles.closeButton}
        >
          <IoCloseCircleOutline size={24} color={'#d8d8d8'} />
        </button>
        <div className={styles.controlsTimerWrapper}>
          <button
            className={styles.controlsButton}
            onClick={playerControlsHandler}
          >
            {getPlayerIcon(status)}
          </button>
          <div className={styles.timer}>
            <span className={styles.timerNumber}>
              {secondsToTime(timer).hours}
            </span>
            <span className={`${styles.timerNumber} ${styles.timerSeparator}`}>
              :
            </span>
            <span className={styles.timerNumber}>
              {secondsToTime(timer).minutes}
            </span>
            <span className={`${styles.timerNumber} ${styles.timerSeparator}`}>
              :
            </span>
            <span className={styles.timerNumber}>
              {secondsToTime(timer).seconds}
            </span>
          </div>
        </div>
        {status === 'paused' && (
          <button
            onClick={stopRecordingHandler}
            className={styles.uploadRecordingButton}
          >
            <img
              src={upload}
              alt="stop"
              width={24}
              height={24}
              className={styles.uploadImage}
            />
            <div className={styles.uploadText}>Upload Recording</div>
          </button>
        )}
      </div>
    </Dialog>
  );
};

export default AudioRecorderComponent;
