import {
  RESPONDENT_EVENT_SAGA,
  ADD_NEXT_EVENT_SAGA,
  CREATE_FEELINGS_SAGA,
  EDIT_EVENT_SAGA,
  GET_CURRENT_RESPONDENT_EVENT_SAGA,
  SET_CURRENT_RESPONDENT_EVENT,
  SET_CURRENT_RESPONDENT_EVENT_ID,
  SET_EVENT_STEPS,
  DELETE_RESPONDENT_EVENT_SAGA,
  SET_FINISHED_STEPS,
  SET_IS_CREATE_EVENT_URL_VALID,
  SET_EVENT_LOWEST_SAGA,
  SET_EVENT_HIGHEST_SAGA,
} from './actionTypes';

export function respondentEventSaga(payload) {
  return {
    type: RESPONDENT_EVENT_SAGA,
    payload,
  };
}

export function deleteRespondentEventSaga(respondentEventId) {
  return {
    type: DELETE_RESPONDENT_EVENT_SAGA,
    respondentEventId,
  };
}

export function setEventSteps(payload) {
  return {
    type: SET_EVENT_STEPS,
    payload,
  };
}

export function setFinishedSteps(payload) {
  return {
    type: SET_FINISHED_STEPS,
    payload,
  };
}

export function editEventSaga(event, index) {
  return {
    type: EDIT_EVENT_SAGA,
    event,
    index,
  };
}

export function createFeelingsSaga(payload) {
  return {
    type: CREATE_FEELINGS_SAGA,
    payload,
  };
}

export function setCurrentRespondentEventId(payload) {
  return {
    type: SET_CURRENT_RESPONDENT_EVENT_ID,
    payload,
  };
}

export function addNextEventSaga() {
  return {
    type: ADD_NEXT_EVENT_SAGA,
  };
}

export function getCurrentRespondentEventSaga(stepId, respondentEventId) {
  return {
    type: GET_CURRENT_RESPONDENT_EVENT_SAGA,
    stepId,
    respondentEventId,
  };
}

export function setCurrentRespondentEvent(payload) {
  return {
    type: SET_CURRENT_RESPONDENT_EVENT,
    payload,
  };
}

export function setIsCreateEventUrlValid(payload) {
  return {
    type: SET_IS_CREATE_EVENT_URL_VALID,
    payload,
  };
}

export function setEventLowestSaga(eventIds) {
  return {
    type: SET_EVENT_LOWEST_SAGA,
    eventIds,
  };
}

export function setEventHighestSaga(eventIds) {
  return {
    type: SET_EVENT_HIGHEST_SAGA,
    eventIds,
  };
}
