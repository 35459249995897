import { $t } from '../translations';

export const HTTP_STATUS_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  VALIDATION_FAILED: 422,
};

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const MONTHS = [
  { id: 1, title: $t('months.january') },
  { id: 2, title: $t('months.february') },
  { id: 3, title: $t('months.march') },
  { id: 4, title: $t('months.april') },
  { id: 5, title: $t('months.may') },
  { id: 6, title: $t('months.june') },
  { id: 7, title: $t('months.july') },
  { id: 8, title: $t('months.august') },
  { id: 9, title: $t('months.september') },
  { id: 10, title: $t('months.october') },
  { id: 11, title: $t('months.november') },
  { id: 12, title: $t('months.december') },
];

export const YEARS = () => {
  let max = new Date().getFullYear();
  let min = max - 99;
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, title: i });
  }
  return years;
};

export const iconColors = {
  audioPlayer: {
    buttonIcon: '#665e00',
  },
  download: '#001b2b',
};

export const getMonthNameByOrder = (order) => {
  switch (order) {
    case 1:
      return $t('months.january');
    case 2:
      return $t('months.february');
    case 3:
      return $t('months.march');
    case 4:
      return $t('months.april');
    case 5:
      return $t('months.may');
    case 6:
      return $t('months.june');
    case 7:
      return $t('months.july');
    case 8:
      return $t('months.august');
    case 9:
      return $t('months.september');
    case 10:
      return $t('months.october');
    case 11:
      return $t('months.november');
    case 12:
      return $t('months.december');
    default:
      return 'N/A';
  }
};
