import { takeLatest, put, select } from 'redux-saga/effects';
import { GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA } from './actionTypes';
import { makeSelectUser } from '../auth/selectors';
import { setCurrentCreateAccountStep } from './actions';
import { push } from 'connected-react-router';

export function* getCurrentCreateAccountStepSaga() {
  try {
    const user = yield select(makeSelectUser());

    if (!user?.respondent_type_id)
      return yield put(setCurrentCreateAccountStep(1));
    if (!user?.condition_id) return yield put(setCurrentCreateAccountStep(2));
    if (!user?.country_id) return yield put(setCurrentCreateAccountStep(3));
    if (!user?.date_of_birth) return yield put(setCurrentCreateAccountStep(4));
    if (!user?.gender_id) return yield put(setCurrentCreateAccountStep(5));
    if (!user?.diagnosedConditions?.length > 0)
      return yield put(setCurrentCreateAccountStep(6));
    if (!user?.relationship_id && !user?.other_relationship)
      return yield put(setCurrentCreateAccountStep(7));
    if (!(user?.relationship_id === 1 && !user?.other_relationship)) {
      if (!user?.care_id) return yield put(setCurrentCreateAccountStep(8));
      if (!user?.age && !user?.deceased && !user.unknown_age)
        return yield put(setCurrentCreateAccountStep(9));
    }
    if (!user?.avatar) return yield put(setCurrentCreateAccountStep(10));
    if (!user?.terms_and_policy)
      return yield put(setCurrentCreateAccountStep(11));
    yield put(push('/'));
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export default function* helperSaga() {
  yield takeLatest(
    GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA,
    getCurrentCreateAccountStepSaga
  );
}
