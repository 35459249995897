import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './AppBar.module.scss';
import maleAvatar from '../../assets/icons/AvatarMale.svg';
import femaleAvatar from '../../assets/icons/AvatarFemale.svg';
import otherAvatar from '../../assets/icons/AvatarOther.svg';
import navbarArrowDown from '../../assets/icons/navbar-arrow-down.svg';
import logoText from '../../assets/images/logo/logo-text.svg';
import { useTranslation } from 'react-i18next';

function AppBar({ user, onLogout, onLanguageChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langEl, setLangEl] = React.useState(null);
  const [chosenLng, setChosenLng] = React.useState(
    localStorage.getItem('language') || 'en'
  );

  const history = useHistory();

  const open = Boolean(anchorEl);
  const openLang = Boolean(langEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLang = (event) => {
    setLangEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setLangEl(null);
  };

  const getProfileAvatar = (gender) => {
    switch (gender) {
      case 'male':
        return maleAvatar;
      case 'female':
        return femaleAvatar;
      default:
        return otherAvatar;
    }
  };
  const handleChooseLng = (val) => {
    localStorage.setItem('language', val);
    setChosenLng(val);
    onLanguageChange(val);
    setLangEl(null);
  };

  const onProfileClick = () => {
    handleClose();
    history.push('/profile');
  };

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.innerWrapper}>
          <Link to={'/'}>
            <img src={logoText} alt="logo" className={styles.image} />
          </Link>
          <div className={styles.profileWrapper}>
            <Button
              id="basic-button"
              aria-controls={openLang ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openLang ? 'true' : undefined}
              onClick={handleClickLang}
            >
              <p className={styles.languageText}>{chosenLng}</p>
            </Button>
            <img
              src={getProfileAvatar(user.avatar)}
              alt="profile"
              width={32}
              height={32}
              className={styles.image}
            />
            <span className={styles.name}>{user.username}</span>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{
                minHeight: 24,
                maxHeight: 24,
                maxWidth: 24,
                minWidth: 24,
              }}
            >
              <img src={navbarArrowDown} alt="arrow-down" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => onProfileClick()}>Profile</MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
            <Menu
              id="basic-menu"
              anchorEl={langEl}
              open={openLang}
              onClose={handleCloseLang}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => handleChooseLng('en')}>
                {t('app_bar.languages.english')}
              </MenuItem>
              <MenuItem onClick={() => handleChooseLng('es')}>
                {t('app_bar.languages.spanish')}
              </MenuItem>
              <MenuItem onClick={() => handleChooseLng('fr')}>
                {t('app_bar.languages.french')}
              </MenuItem>
              <MenuItem onClick={() => handleChooseLng('de')}>
                {t('app_bar.languages.deutsch')}
              </MenuItem>
              <MenuItem onClick={() => handleChooseLng('md')}>
                {t('app_bar.languages.mandarin')}
              </MenuItem>
              <MenuItem onClick={() => handleChooseLng('jp')}>
                {t('app_bar.languages.japanese')}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Container>
    </div>
  );
}

AppBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppBar;
