import {
  SET_IS_SUBMIT_MODAL_OPEN,
  START_JOURNEY_SAGA,
  SUBMIT_JOURNEY_SAGA,
} from './actionTypes';

export function startJourneySaga() {
  return {
    type: START_JOURNEY_SAGA,
  };
}

export function submitJourneySaga(payload) {
  return {
    type: SUBMIT_JOURNEY_SAGA,
    payload,
  };
}

export function setIsSubmitModalOpen(payload) {
  return {
    type: SET_IS_SUBMIT_MODAL_OPEN,
    payload,
  };
}
