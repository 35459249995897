import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

const Button = ({
  title,
  className,
  disabled,
  inverted,
  small,
  medium,
  outline,
  login,
  width,
  height,
  ...rest
}) => {
  const buttonClasses = classNames(
    styles.button,
    {
      [styles.inverted]: inverted,
      [styles.disabled]: disabled,
      [styles.small]: small,
      [styles.medium]: medium,
      [styles.outline]: outline,
      [styles.login]: login,
    },
    className
  );

  return (
    <button
      className={buttonClasses}
      style={{ width: width, height: height }}
      disabled={disabled}
      {...rest}
    >
      {title}
    </button>
  );
};

export default Button;
