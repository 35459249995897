import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en.json';
import translationDe from './locales/de.json';
import translationEs from './locales/es.json';
import translationFr from './locales/fr.json';
import translationMd from './locales/md.json';
import translationJp from './locales/jp.json';

const LOCALE_ENG = 'en';
const LOCALE_DE = 'de';
const LOCALE_ES = 'es';
const LOCALE_FR = 'fr';
const LOCALE_MD = 'md';
const LOCALE_JP = 'jp';

export const LOCALES = [
  LOCALE_ENG,
  LOCALE_DE,
  LOCALE_ES,
  LOCALE_FR,
  LOCALE_MD,
  LOCALE_JP,
];

export const DEFAULT_LOCALE = LOCALE_ENG;

const resources = {
  en: {
    translation: translationEn,
  },
  de: {
    translation: translationDe,
  },
  es: {
    translation: translationEs,
  },
  fr: {
    translation: translationFr,
  },
  md: {
    translation: translationMd,
  },
  jp: {
    translation: translationJp,
  },
};

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('language') || DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  debug:
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export const $t = (key, params = {}) => {
  return i18n.t(key, params);
};

export default i18n;
