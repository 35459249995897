import React from 'react';
import styles from './CreateEventPage.module.scss';
import { BiCheck } from 'react-icons/all';

const StepIndicator = ({ steps, activeStep, finishedSteps }) => {
  return (
    <div className={styles.indicatorWrapper}>
      <div className={styles.indicatorLineWrapper}>
        <div className={styles.indicatorLine} />
      </div>
      {steps?.map((step, index) => {
        return (
          <div
            key={step.id}
            className={styles.indicatorThumb}
            style={{
              background:
                activeStep === index + 1
                  ? '#5f697a'
                  : finishedSteps.includes(index + 1)
                  ? '#FFF200'
                  : '#EAEEF2',
              color: activeStep === index + 1 ? 'white' : '#001b2b',
            }}
          >
            {activeStep !== index + 1 && finishedSteps.includes(index + 1) ? (
              <BiCheck size={24} />
            ) : (
              index + 1
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StepIndicator;
