import React from 'react';
import styles from './IconButton.module.scss';

const IconButton = ({title, icon, ...props}) => {
  return (
    <button className={styles.container} {...props}>
      <div className={styles.icon}>
        {icon}
      </div>
      {title}
    </button>
  );
};

export default IconButton;
