import { GET_JOURNEY_SAGA, SET_JOURNEY } from './actionTypes';

export function getJourneySaga(journeyId) {
  return {
    type: GET_JOURNEY_SAGA,
    journeyId,
  };
}

export function setJourney(payload) {
  return {
    type: SET_JOURNEY,
    payload,
  };
}
