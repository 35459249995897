import React from 'react';
import styles from './CreateEvent.module.scss';

const Tooltip = ({ title }) => {
  return (
    <div className={styles.tooltipWrapper}>
      <div className={styles.tooltip}>
        <div className={styles.tooltipInner}>{title}</div>
      </div>
    </div>
  );
};

export default Tooltip;
