import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import loadingReducer from './loading/reducer';
import notifierReducer from './notifier/reducer';
import authReducer from './auth/reducer';
import profileReducer from './profile/reducer';
import snackbarReducer from './snackbar/reducer';
import respondentEventReducer from './respondentEvent/reducer';
import helperReducer from './helper/reducer';
import respondentReducer from './respondent/reducer';
import journeyReducer from './journey/reducer';
import loaderReducer from './loader/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    loading: loadingReducer,
    notifier: notifierReducer,
    auth: authReducer,
    profile: profileReducer,
    snackbar: snackbarReducer,
    respondentEvent: respondentEventReducer,
    helper: helperReducer,
    respondent: respondentReducer,
    journey: journeyReducer,
    loader: loaderReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
