import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './CFQuestionDialog.module.scss';

const CFQuestionDialog = ({
  title,
  subtitle,
  handleClose,
  isDialogOpen,
  confirm,
  confirmButtonTitle,
  declineButtonTitle,
}) => {
  return (
    <Dialog onClose={handleClose} open={isDialogOpen} disableScrollLock={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        <div className={styles.buttonsWrapper}>
          <button
            onClick={handleClose}
            className={`${styles.button} ${styles.declineButton}`}
          >
            {declineButtonTitle}
          </button>
          <button
            onClick={confirm}
            className={`${styles.button} ${styles.confirmButton}`}
          >
            {confirmButtonTitle}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CFQuestionDialog;
