import produce from 'immer';

import {
  SET_CURRENT_RESPONDENT_EVENT,
  SET_CURRENT_RESPONDENT_EVENT_ID,
  SET_EVENT_STEPS,
  SET_FINISHED_STEPS,
  SET_IS_CREATE_EVENT_URL_VALID,
} from './actionTypes';

export const initialState = {
  eventSteps: [],
  finishedSteps: [],
  currentRespondentEventId: null,
  activeRespondentEvent: null,
  isCreateEventUrlValid: null,
};

/* eslint-disable default-case */

const respondentEventReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_EVENT_STEPS:
        if (Array.isArray(payload)) {
          draft.eventSteps = payload;
        } else {
          draft.eventSteps = [
            ...draft.eventSteps.filter((event) => event.id !== payload.id),
            payload,
          ];
        }
        break;
      case SET_FINISHED_STEPS:
        if (Array.isArray(payload)) {
          draft.finishedSteps = payload;
        } else {
          draft.finishedSteps = [...draft.finishedSteps, payload];
        }
        break;
      case SET_CURRENT_RESPONDENT_EVENT_ID:
        draft.currentRespondentEventId = payload;
        break;
      case SET_CURRENT_RESPONDENT_EVENT:
        draft.activeRespondentEvent = payload;
        break;
      case SET_IS_CREATE_EVENT_URL_VALID:
        draft.isCreateEventUrlValid = payload;
        break;
    }
  });

export default respondentEventReducer;
