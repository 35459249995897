import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_ALL_FEELINGS: '/feelings',
  CREATE_FEELINGS: '/feelings/by-respondent',
};

class FeelingService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getAllFeelings = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_FEELINGS,
      method: HTTP_METHODS.GET,
    });
  };

  createFeelings = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_FEELINGS,
      method: HTTP_METHODS.POST,
      data,
    });
  };
}

const feelingService = new FeelingService(httpService);

export default feelingService;
