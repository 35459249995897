import React from 'react';
import styles from './CreateEventPage.module.scss';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/Microphone.svg';
import { ReactComponent as CameraIcon } from '../../assets/icons/Camera.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import IconButton from '../../components/Buttons/IconButton';
import TextAreaComponent from '../../components/CreateEvent/TextAreaComponent';
import moment from 'moment';
import MediaButton from '../../components/Buttons/MediaButton';
import Tooltip from '../../components/CreateEvent/Tooltip';
import { $t } from '../../translations';

const StepDetails = ({
  details,
  onChangeDetails,
  activeStepEvent,
  activeStep,
  recordAudio,
  recordVideo,
  video,
  audio,
  removeVideo,
  removeAudio,
  playVideo,
  playAudio,
  isTooltipVisible,
}) => {
  return (
    <div className={styles.detailsWrapper}>
      {isTooltipVisible && !details && (
        <Tooltip title={$t('create_event_page.details.tooltip')} />
      )}
      <h4 className={styles.detailsTitle}>
        {$t('create_event_page.details.title')}
      </h4>
      <div
        className={`${styles.detailsBoxWrapper} ${
          isTooltipVisible && !details && styles.animateBorder
        }`}
      >
        <TextAreaComponent
          value={details}
          onChange={onChangeDetails}
          activeStepEvent={activeStepEvent}
          activeStep={activeStep}
        />
        <div className={styles.detailsButtonsWrapper}>
          <div className={styles.detailsLeftButton}>
            {audio ? (
              <MediaButton
                title={`${$t(
                  'create_event_page.details.buttons.audio_recording'
                )} ${moment().format('MM-DD-YYYY')}`}
                leftIcon={<PlayIcon />}
                rightIcon={<TrashIcon />}
                onLeftButtonClick={playAudio}
                onRightButtonClick={removeAudio}
              />
            ) : (
              <IconButton
                title={$t('create_event_page.details.buttons.record_audio')}
                icon={<MicrophoneIcon />}
                onClick={recordAudio}
              />
            )}
          </div>
          {video ? (
            <MediaButton
              title={`${$t(
                'create_event_page.details.buttons.video_recording'
              )} ${moment().format('MM-DD-YYYY')}`}
              leftIcon={<PlayIcon />}
              rightIcon={<TrashIcon />}
              onLeftButtonClick={playVideo}
              onRightButtonClick={removeVideo}
            />
          ) : (
            <IconButton
              title={$t('create_event_page.details.buttons.record_video')}
              icon={<CameraIcon />}
              onClick={recordVideo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepDetails;
