export const RESPONDENT_EVENT = '[RespondentEvent]';

export const RESPONDENT_EVENT_SAGA = `${RESPONDENT_EVENT} RESPONDENT_EVENT_SAGA`;
export const DELETE_RESPONDENT_EVENT_SAGA = `${RESPONDENT_EVENT} DELETE_RESPONDENT_EVENT_SAGA`;
export const SET_EVENT_STEPS = `${RESPONDENT_EVENT} SET_EVENT_STEPS`;
export const SET_FINISHED_STEPS = `${RESPONDENT_EVENT} SET_FINISHED_STEPS`;
export const EDIT_EVENT_SAGA = `${RESPONDENT_EVENT} EDIT_EVENT_SAGA`;
export const CREATE_FEELINGS_SAGA = `${RESPONDENT_EVENT} CREATE_FEELINGS_SAGA`;
export const SET_CURRENT_RESPONDENT_EVENT_ID = `${RESPONDENT_EVENT} SET_CURRENT_RESPONDENT_EVENT_ID`;
export const ADD_NEXT_EVENT_SAGA = `${RESPONDENT_EVENT} ADD_NEXT_EVENT_SAGA`;
export const GET_CURRENT_RESPONDENT_EVENT_SAGA = `${RESPONDENT_EVENT} GET_CURRENT_RESPONDENT_EVENT_SAGA`;
export const SET_CURRENT_RESPONDENT_EVENT = `${RESPONDENT_EVENT} SET_CURRENT_RESPONDENT_EVENT`;
export const SET_IS_CREATE_EVENT_URL_VALID = `${RESPONDENT_EVENT} SET_IS_CREATE_EVENT_URL_VALID`;
export const SET_EVENT_LOWEST_SAGA = `${RESPONDENT_EVENT} SET_EVENT_LOWEST_SAGA`;
export const SET_EVENT_HIGHEST_SAGA = `${RESPONDENT_EVENT} SET_EVENT_HIGHEST_SAGA`;
