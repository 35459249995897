export const secondsToTime = (value) => {
  let seconds = Math.floor((value % 3600) % 60);
  let minutes = Math.floor((value % 3600) / 60);
  let hours = Math.floor(value / 3600);

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (hours < 10) {
    hours = '0' + hours;
  }

  return { hours, minutes, seconds };
};
