import React from 'react';
import styles from './MediaButton.module.scss';

const MediaButton = ({
  title,
  leftIcon,
  rightIcon,
  onLeftButtonClick,
  onRightButtonClick,
}) => {
  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onLeftButtonClick}>
        <div className={styles.icon}>{leftIcon}</div>
      </button>
      <div className={styles.title}>{title}</div>
      <button className={styles.button} onClick={onRightButtonClick}>
        <div className={styles.icon}>{rightIcon}</div>
      </button>
    </div>
  );
};

export default MediaButton;
