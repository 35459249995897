import produce from 'immer';
import { SET_IS_SUBMIT_MODAL_OPEN } from './actionTypes';
import { SET_CURRENT_CREATE_ACCOUNT_STEP } from '../helper/actionTypes';

export const initialState = {
  isSubmitModalOpen: false,
};

/* eslint-disable default-case */
const respondentReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_IS_SUBMIT_MODAL_OPEN:
        draft.isSubmitModalOpen = payload;
        break;
    }
  });

export default respondentReducer;
