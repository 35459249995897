import React from 'react';
import { Grid } from '@mui/material';
import styles from './CreateEventPage.module.scss';
import Button from '../../components/Buttons/Button';
import StepDetails from './StepDetails';
import { MdChevronLeft, BiCheck } from 'react-icons/all';
import CFDropdown from '../../sharedComponents/Dropdown';
import { ReactComponent as ArrowDown } from '../../assets/icons/dropdown-arrow-down.svg';
import { MONTHS, YEARS } from '../../consts';
import { $t } from '../../translations';
import InputComponent from '../../components/CreateEvent/InputComponent';

const CreateEventStep = ({
  events,
  selectedEvents,
  setSelectedEvents,
  selectedEventOtherValue,
  setSelectedEventOtherValue,
  steps,
  eventSteps,
  activeStep,
  onSubmit,
  details,
  onChangeDetails,
  activeStepEvent,
  onClickBack,
  recordAudio,
  recordVideo,
  video,
  audio,
  removeVideo,
  removeAudio,
  playVideo,
  playAudio,
  onDiscardEventClick,
  selectedMonth,
  selectedYear,
  setSelectedYear,
  setSelectedMonth,
  isTooltipVisible,
  setIsTooltipVisible,
  isSubmitButtonDisabled,
}) => {
  const dropdownConfigYears = {
    options: YEARS()?.map((month, index) => {
      return { id: index, value: month.id, label: month.title };
    }),
  };

  const dropdownConfigMonths = {
    options: MONTHS?.map((month, index) => {
      return { id: index, value: month.id, label: month.title };
    }),
  };

  const onEventClick = (event) => {
    setSelectedEvents(event);
    setIsTooltipVisible(true);
  };

  const onContinueClick = () => {
    onSubmit();
    setIsTooltipVisible(false);
  };

  return (
    <div className={styles.mainWrapper}>
      {activeStep !== 1 && (
        <button onClick={onClickBack} className={styles.returnButton}>
          <MdChevronLeft size={24} />
          <span className={styles.returnButtonTitle}>
            {$t('create_event_page.navigation.return')}
          </span>
        </button>
      )}
      <h1 className={styles.title}>{steps[activeStep - 1]?.step.name}</h1>
      {activeStep === 1 && (
        <div>
          <p className={styles.paragraph}>{$t('create_event_page.question')}</p>
          <div className={styles.dropdownsWrapper}>
            <div className={styles.leftDropdown}>
              <CFDropdown
                title={$t('create_event_page.dropdown.month')}
                dropdownConfig={dropdownConfigMonths}
                value={selectedMonth}
                handleChange={(event) => setSelectedMonth(event.target.value)}
                icon={ArrowDown}
                width={335}
              />
            </div>
            <div>
              <CFDropdown
                title={$t('create_event_page.dropdown.year')}
                dropdownConfig={dropdownConfigYears}
                value={selectedYear}
                handleChange={(event) => setSelectedYear(event.target.value)}
                icon={ArrowDown}
                width={335}
              />
            </div>
          </div>
        </div>
      )}
      <h4 className={styles.subtitle}>
        {steps[activeStep - 1]?.step.question}
      </h4>
      <h5 className={styles.instructions}>
        {$t('create_event_page.instructions')}
      </h5>
      <Grid
        container
        className={styles.itemListWrapper}
        style={{ border: `1px solid ${steps[activeStep - 1]?.step.color}` }}
      >
        {events?.map((event) => {
          return (
            <Grid
              key={event.id}
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className={styles.itemWrapper}
            >
              <div
                style={{
                  background: selectedEvents.find(
                    (selectedEvent) => selectedEvent.id === event.id
                  )
                    ? 'transparent'
                    : steps[activeStep - 1]?.step.color,
                  border: selectedEvents.find(
                    (selectedEvent) => selectedEvent.id === event.id
                  )
                    ? `1px solid ${steps[activeStep - 1]?.step.color}`
                    : 'none',
                }}
                className={styles.item}
                onClick={() => onEventClick(event)}
              >
                {selectedEvents.find(
                  (selectedEvent) => selectedEvent.id === event.id
                ) && (
                  <div className={styles.selectedItemCheckedIcon}>
                    <BiCheck size={24} color={'white'} />
                  </div>
                )}
                <img
                  src={event.icon.url}
                  className={styles.itemIcon}
                  alt={event.name}
                />
                <div className={styles.textWrapper}>
                  <h4 className={styles.itemTitle}>{event.name}</h4>
                </div>
              </div>
            </Grid>
          );
        })}
        {selectedEvents.find(
          (selectedEvent) => selectedEvent.name === 'Other'
        ) && (
          <InputComponent
            value={selectedEventOtherValue}
            onChange={setSelectedEventOtherValue}
            activeStepEvent={activeStepEvent}
            activeStep={activeStep}
          />
        )}
      </Grid>
      <StepDetails
        details={details}
        onChangeDetails={onChangeDetails}
        activeStepEvent={activeStepEvent}
        activeStep={activeStep}
        recordAudio={recordAudio}
        recordVideo={recordVideo}
        video={video}
        audio={audio}
        removeVideo={removeVideo}
        removeAudio={removeAudio}
        playVideo={playVideo}
        playAudio={playAudio}
        isTooltipVisible={isTooltipVisible}
      />
      <div className={styles.submitButtonWrapper}>
        <div className={styles.discardButton}>
          {eventSteps.length > 0 && (
            <Button
              title={$t('create_event_page.buttons.discard')}
              small
              outline
              onClick={() => {
                onDiscardEventClick();
                setSelectedMonth('');
                setSelectedYear('');
              }}
            />
          )}
        </div>
        <Button
          title={$t('create_event_page.buttons.continue')}
          small
          onClick={onContinueClick}
          disabled={isSubmitButtonDisabled}
        />
      </div>
    </div>
  );
};

export default CreateEventStep;
