import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@material-ui/core';

const CFDropdown = ({
  title,
  value,
  width,
  handleChange,
  icon,
  dropdownConfig,
}) => {
  const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        width: width,
        '@media (max-width: 900px)': {
          width: width - 100,
        },
        '@media (max-width: 600px)': {
          width: '100%',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#001b2b',
        },
        '&:hover fieldset': {
          borderColor: '#001b2b',
        },
      },
      '& fieldset': {
        border: 'none',
      },
      '& .MuiInputLabel-root': {
        fontFamily: 'SuisseIntl-Regular',
        '&.Mui-focused': {
          color: '#001b2b',
        },
      },
      '& .MuiSelect-select': {
        backgroundColor: '#EFEFF2',
      },
      '& .MuiSelect-icon': {
        top: 'auto',
        right: '25px',
      },
    },
  });

  const classes = useStyles();

  const [options, setOptions] = useState([]);

  const getDataForDropdown = async () => {
    const res = await dropdownConfig.options.apiService[
      dropdownConfig.options.apiServiceMethod
    ]();
    let filteredOptions = [];
    res.forEach((item) => {
      const data = {};
      data.id = item.id;
      data.value = item.id;
      data.label = item[dropdownConfig.options.labelKey];
      filteredOptions.push(data);
    });
    setOptions(filteredOptions);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (dropdownConfig.options.apiService) {
        getDataForDropdown();
      } else {
        setOptions(dropdownConfig.options);
      }
    }
    return () => (mounted = false);
  }, [dropdownConfig.options.apiService]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl fullWidth className={`${classes.root}`}>
      <InputLabel id="label" style={{ fontSize: '16px' }}>
        {title}
      </InputLabel>
      <Select
        labelId="label"
        id="select"
        value={value}
        label={title}
        onChange={handleChange}
        IconComponent={icon}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: { sx: { maxHeight: 300 } },
        }}
      >
        <MenuItem value="">None</MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CFDropdown;
