import { call, put, takeLatest } from 'redux-saga/effects';
import { START_JOURNEY_SAGA, SUBMIT_JOURNEY_SAGA } from './actionTypes';
import respondentService from '../../services/RespondentService';
import { push } from 'connected-react-router';
import { CREATE_EVENT_PAGE } from '../../routes';
import authService from '../../services/AuthService';
import { fetchAuthenticatedUserSuccess } from '../auth/actions';
import { setLoader } from '../loader/actions';
import {
  setIsDownloadJourneyDialogOpen,
  setIsFinalFeelingsDialogOpen,
} from '../helper/actions';
import { setIsSubmitModalOpen } from './actions';

export function* startJourneySaga() {
  try {
    yield call(respondentService.startJourney);
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(push(CREATE_EVENT_PAGE.replace(':stepId', (1).toString())));
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* submitJourneySaga({ payload }) {
  try {
    const language = localStorage.getItem('language')
      ? localStorage.getItem('language')
      : 'en';
    yield put(setLoader(true));
    let data = {
      language: language,
      feelings: payload,
    };
    yield put(setIsSubmitModalOpen(false));
    yield call(respondentService.submitJourney, data);
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(setIsDownloadJourneyDialogOpen(true));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export default function* respondentSaga() {
  yield takeLatest(START_JOURNEY_SAGA, startJourneySaga);
  yield takeLatest(SUBMIT_JOURNEY_SAGA, submitJourneySaga);
}
