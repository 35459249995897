import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  RESPONDENT_EVENT_SAGA,
  ADD_NEXT_EVENT_SAGA,
  CREATE_FEELINGS_SAGA,
  EDIT_EVENT_SAGA,
  GET_CURRENT_RESPONDENT_EVENT_SAGA,
  DELETE_RESPONDENT_EVENT_SAGA,
  SET_EVENT_LOWEST_SAGA,
  SET_EVENT_HIGHEST_SAGA,
} from './actionTypes';
import {
  currentRespondentEventIdSelector,
  eventStepsSelector,
} from './selectors';
import respondentEventService from '../../services/RespondentEventService';
import {
  setCurrentRespondentEvent,
  setCurrentRespondentEventId,
  setEventSteps,
  setFinishedSteps,
  setIsCreateEventUrlValid,
} from './actions';
import { push } from 'connected-react-router';
import {
  CREATE_EVENT_PAGE,
  EDIT_EVENT_PAGE,
  EVENT_LIST_PAGE,
  LOGIN,
} from '../../routes';
import { setIsFeelingsDialogOpen } from '../helper/actions';
import feelingService from '../../services/FeelingService';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';
import { journeySelector } from '../journey/selectors';
import authService from '../../services/AuthService';
import { logoutSuccess } from '../auth/actions';
import { removeItem } from '../../utils/localStorage';

export function* handleRespondentEventSaga({ payload }) {
  try {
    const eventSteps = yield select(eventStepsSelector());

    let respondentStep;

    let formData = new FormData();
    formData.append('step_id', payload.step_id);
    if (
      payload.selectedEvents?.find(
        (selectedEvent) => selectedEvent.name === 'Other'
      )
    ) {
      formData.append('other_value', payload.other_value);
    } else {
      formData.append('other_value', '');
    }
    formData.append(
      'eventIds',
      JSON.stringify(payload.selectedEvents.map((event) => event.id))
    );
    formData.append('details', payload.details);
    formData.append('audio', payload.audio);
    formData.append('video', payload.video);
    if (payload.activeStep === 1) {
      formData.append('month', payload.month);
      formData.append('year', payload.year);
    }

    let data = {
      id: payload.activeStep,
      selectedEvents: payload.selectedEvents,
      ...(payload.selectedEvents?.find(
        (selectedEvent) => selectedEvent.name === 'Other'
      ) && {
        other_value: payload.other_value,
      }),
      step_id: payload.step_id,
      details: payload.details,
      ...(payload.activeStep === 1 && {
        month: payload.month,
        year: payload.year,
      }),
    };

    if (
      payload.activeStep === 1 &&
      !eventSteps?.find((step) => step.id === 1)
    ) {
      respondentStep = yield call(
        respondentEventService.createRespondentEvent,
        formData
      );
    } else {
      let eventStep = eventSteps?.find(
        (step) => step.id === payload.activeStep
      );
      if (eventStep) {
        if (eventStep.eventStepId) {
          respondentStep = yield call(
            respondentEventService.updateRespondentStep,
            formData,
            eventStep.eventStepId
          );
        }
      } else {
        respondentStep = yield call(
          respondentEventService.createRespondentStep,
          formData
        );
      }
    }

    data.audio = respondentStep.audio;
    data.video = respondentStep.video;
    data.eventStepId = respondentStep.id;

    yield put(setCurrentRespondentEventId(respondentStep.respondent_event_id));
    yield put(setEventSteps(data));

    if (payload.lastStep) {
      const currentRespondentEventId = yield select(
        currentRespondentEventIdSelector()
      );
      const respondentEvent = yield call(
        respondentEventService.getRespondentEvent,
        currentRespondentEventId
      );

      if (!respondentEvent?.feelings?.length > 0) {
        yield put(setIsFeelingsDialogOpen(true));
      }
      yield put(push(EVENT_LIST_PAGE));
    } else {
      if (payload.respondentEventId) {
        yield put(
          push(
            EDIT_EVENT_PAGE.replace(
              ':respondentEventId',
              payload.respondentEventId
            ).replace(':stepId', (payload.activeStep + 1).toString())
          )
        );
      } else {
        yield put(
          push(
            CREATE_EVENT_PAGE.replace(
              ':stepId',
              (payload.activeStep + 1).toString()
            )
          )
        );
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* deleteRespondentEventSaga({ respondentEventId }) {
  try {
    yield call(respondentEventService.deleteRespondentEvent, respondentEventId);
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.respondent-event.discarded'),
      })
    );
    yield put(setEventSteps([]));
    yield put(setCurrentRespondentEventId(null));
    yield put(push(EVENT_LIST_PAGE));
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* editEventSaga({ event, index }) {
  try {
    yield logoutOnProjectClosed();

    let dataArray = [];
    event.respondentSteps.forEach((step, index) => {
      const data = {
        id: index + 1,
        selectedEvents: step.events,
        other_value: step.other_value,
        step_id: step.step_id,
        details: step.details,
        ...(index === 0 && {
          month: event.month,
          year: event.year,
        }),
        audio: step.audio,
        video: step.video,
        eventStepId: step.id,
      };

      dataArray.push(data);
    });
    yield put(setFinishedSteps([...Array(dataArray.length + 1).keys()]));
    yield put(setEventSteps(dataArray));
    yield put(setCurrentRespondentEventId(event.id));
    yield put(
      push(
        EDIT_EVENT_PAGE.replace(
          ':respondentEventId',
          event.id.toString()
        ).replace(':stepId', (index + 2).toString())
      )
    );
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* createFeelingsSaga({ payload }) {
  try {
    const respondentEventId = yield select(currentRespondentEventIdSelector());
    yield put(setIsFeelingsDialogOpen(false));
    const data = {
      respondent_event_id: respondentEventId,
      feelings: payload,
    };
    yield call(feelingService.createFeelings, data);
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* addNextEventSaga() {
  try {
    yield logoutOnProjectClosed();

    const activeRespondentEvent = yield call(
      respondentEventService.getActiveRespondentEvent
    );
    if (!activeRespondentEvent) {
      yield put(setFinishedSteps([]));
      yield put(setEventSteps([]));
    }
    yield put(push(CREATE_EVENT_PAGE.replace(':stepId', (1).toString())));
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* getCurrentRespondentEventSaga({ stepId, respondentEventId }) {
  try {
    yield put(setEventSteps([]));
    yield put(setCurrentRespondentEventId(null));
    yield put(setFinishedSteps([]));
    const eventSteps = yield select(eventStepsSelector());
    const journey = yield select(journeySelector());

    let currentRespondentEvent;
    if (respondentEventId) {
      currentRespondentEvent = yield call(
        respondentEventService.getRespondentEvent,
        respondentEventId
      );
    } else {
      currentRespondentEvent = yield call(
        respondentEventService.getActiveRespondentEvent
      );
    }

    let dataArray = [];
    if (currentRespondentEvent) {
      currentRespondentEvent.respondentSteps.forEach((step, index) => {
        const data = {
          id: index + 1,
          selectedEvents: step.events,
          other_value: step.other_value,
          step_id: step.step_id,
          details: step.details,
          ...(index === 0 && {
            month: currentRespondentEvent.month,
            year: currentRespondentEvent.year,
          }),
          audio: step.audio,
          video: step.video,
          eventStepId: step.id,
        };

        dataArray.push(data);
      });

      yield put(setFinishedSteps([...Array(dataArray.length + 1).keys()]));
      yield put(setEventSteps(dataArray));
      yield put(setCurrentRespondentEventId(currentRespondentEvent.id));
      yield put(setCurrentRespondentEvent(currentRespondentEvent));
    }

    if (journey) {
      if (
        !/^\d+$/.test(stepId) ||
        parseInt(stepId) > journey?.stepJourneys?.length
      ) {
        return yield put(setIsCreateEventUrlValid(false));
      }
      if (respondentEventId && !currentRespondentEvent) {
        return yield put(setIsCreateEventUrlValid(false));
      }
      if (eventSteps.length > 0 && eventSteps?.length < parseInt(stepId) - 1) {
        yield put(
          push(
            CREATE_EVENT_PAGE.replace(
              ':stepId',
              (eventSteps?.length + 1).toString()
            )
          )
        );
      }
      return yield put(setIsCreateEventUrlValid(true));
    }
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

function* logoutOnProjectClosed() {
  const user = yield call(authService.fetchAuthenticatedUser);

  if (user?.project?.status === 'closed') {
    yield put(logoutSuccess());
    yield call(removeItem, 'token');
    yield put(push(LOGIN));
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.project.closed'),
      })
    );

    return;
  }
}

export function* setEventLowestSaga({ eventIds }) {
  try {
    const data = { ids: eventIds };
    yield call(respondentEventService.setRespondentEventsLowest, data);
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export function* setEventHighestSaga({ eventIds }) {
  try {
    const data = { ids: eventIds };
    yield call(respondentEventService.setRespondentEventsHighest, data);
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export default function* respondentEventSaga() {
  yield takeLatest(RESPONDENT_EVENT_SAGA, handleRespondentEventSaga);
  yield takeLatest(DELETE_RESPONDENT_EVENT_SAGA, deleteRespondentEventSaga);
  yield takeLatest(EDIT_EVENT_SAGA, editEventSaga);
  yield takeLatest(CREATE_FEELINGS_SAGA, createFeelingsSaga);
  yield takeLatest(ADD_NEXT_EVENT_SAGA, addNextEventSaga);
  yield takeLatest(
    GET_CURRENT_RESPONDENT_EVENT_SAGA,
    getCurrentRespondentEventSaga
  );
  yield takeLatest(SET_EVENT_LOWEST_SAGA, setEventLowestSaga);
  yield takeLatest(SET_EVENT_HIGHEST_SAGA, setEventHighestSaga);
}
