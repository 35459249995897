import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_JOURNEY_SAGA } from './actionTypes';
import journeyService from '../../services/JourneyService';
import { setJourney } from './actions';

export function* getJourneySaga({ journeyId }) {
  try {
    const journey = yield call(journeyService.getJourney, journeyId);
    yield put(setJourney(journey));
  } catch (error) {
    console.log({ error });
  } finally {
  }
}

export default function* journeySaga() {
  yield takeLatest(GET_JOURNEY_SAGA, getJourneySaga);
}
