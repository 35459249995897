import React, { useEffect } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import styles from './CreateEvent.module.scss';
import { $t } from '../../translations';

const TextAreaComponent = ({
  value,
  onChange,
  activeStepEvent,
  activeStep,
}) => {
  useEffect(() => {
    if (!activeStepEvent?.details || activeStepEvent?.details === '') {
      onChange('');
    } else {
      onChange(activeStepEvent?.details);
    }
  }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextareaAutosize
      aria-label="empty textarea"
      placeholder={$t('create_event_page.details.placeholder')}
      minRows={5}
      className={styles.textarea}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  );
};

export default TextAreaComponent;
