import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRespondentEvent = (state) => state.respondentEvent || initialState;

const eventStepsSelector = () =>
  createSelector(selectRespondentEvent, (state) => state.eventSteps);

const finishedStepsSelector = () =>
  createSelector(selectRespondentEvent, (state) => state.finishedSteps);

const currentRespondentEventIdSelector = () =>
  createSelector(
    selectRespondentEvent,
    (state) => state.currentRespondentEventId
  );

const activeRespondentEventSelector = () =>
  createSelector(selectRespondentEvent, (state) => state.activeRespondentEvent);

const isCreateEventUrlValidSelector = () =>
  createSelector(selectRespondentEvent, (state) => state.isCreateEventUrlValid);

export {
  eventStepsSelector,
  currentRespondentEventIdSelector,
  activeRespondentEventSelector,
  finishedStepsSelector,
  isCreateEventUrlValidSelector,
};
