import record from '../assets/icons/mic.svg';
import play from '../assets/icons/play-circle.svg';
import stop from '../assets/icons/stop-circle.svg';
import React from 'react';

export const getPlayerIcon = (status) => {
  switch (status) {
    case 'idle':
      return (
        <img
          src={record}
          alt="stop"
          width={44}
          height={44}
          style={{ display: 'block' }}
        />
      );
    case 'paused':
      return (
        <img
          src={play}
          alt="play"
          width={44}
          height={44}
          style={{ display: 'block' }}
        />
      );
    case 'recording':
      return (
        <img
          src={stop}
          alt="play"
          width={44}
          height={44}
          style={{ display: 'block' }}
        />
      );
    default:
      return (
        <img
          src={stop}
          alt="stop"
          width={44}
          height={44}
          style={{ display: 'block' }}
        />
      );
  }
};
