import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_JOURNEY: '/journeys/:journeyId',
};

class JourneyService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getJourney = (journeyId) => {
    return this.httpService.request({
      url: ROUTES.GET_JOURNEY.replace(':journeyId', journeyId),
      method: HTTP_METHODS.GET,
    });
  };
}

const journeyService = new JourneyService(httpService);

export default journeyService;
