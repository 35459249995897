import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess,
  loginSuccess,
  loginError,
  setToken,
  setLoginError,
  updateAuthenticatedUser,
} from './actions';
import { startAction, stopAction } from '../loading/actions';
import {
  LOGIN_REQUEST,
  FETCH_AUTHENTICATED_USER_REQUEST,
  LOGOUT_REQUEST,
  UPDATE_SAGA,
} from './actionTypes';
import { CREATE_ACCOUNT_PAGE, LOGIN } from 'routes';
import authService from 'services/AuthService';
import { HTTP_STATUS_CODES } from 'consts';
import { removeItem } from '../../utils/localStorage';

export function* authorize({ type, username, password }) {
  try {
    yield put(startAction(type));
    const res = yield call(authService.login, { username, password });
    if (res) {
      const user = yield call(authService.fetchAuthenticatedUser);
      yield put(loginSuccess());
      yield put(setToken('token', res));
      if (user?.terms_and_policy) {
        yield put(push('/'));
      } else {
        yield put(push(CREATE_ACCOUNT_PAGE));
      }
    }
  } catch (error) {
    if (error && error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(stopAction(type));
  }
}

export function* fetchUser({ type }) {
  yield put(startAction(type));
  try {
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(stopAction(type));
  }
}

export function* logout() {
  try {
    yield put(logoutSuccess());
    yield call(removeItem, 'token');
    yield put(push(LOGIN));
  } catch (error) {
    //
  }
}

export function* updateSaga({ payload, isLast }) {
  try {
    const res = yield call(authService.update, payload);
    yield put(fetchAuthenticatedUserSuccess(res));

    if (isLast) {
      yield put(updateAuthenticatedUser({ terms_and_policy: 1 }));
      const user = yield call(authService.fetchAuthenticatedUser);
      yield put(fetchAuthenticatedUserSuccess(user));
      yield put(push('/'));
    }
  } catch (error) {
    console.log({ error });
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(UPDATE_SAGA, updateSaga);
}
