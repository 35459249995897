import produce from 'immer';
import { SET_JOURNEY } from './actionTypes';

export const initialState = {};

/* eslint-disable default-case */
const journeyReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_JOURNEY:
        draft.journey = payload;
        break;
    }
  });

export default journeyReducer;
