import { fork } from 'redux-saga/effects';

import AuthSagas from './auth/sagas';
import ProfileSagas from './profile/sagas';
import RespondentEventSagas from './respondentEvent/sagas';
import helperSagas from './helper/sagas';
import respondentSagas from './respondent/sagas';
import journeySagas from './journey/sagas';

export default function* rootSaga() {
  yield fork(AuthSagas);
  yield fork(ProfileSagas);
  yield fork(RespondentEventSagas);
  yield fork(helperSagas);
  yield fork(respondentSagas);
  yield fork(journeySagas);
}
